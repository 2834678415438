import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import HeaderMap from '../components/HeaderMap';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import ContentReverse from '../components/ContentReverse';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 11%;

    @media (min-width: 768px) and (max-width: 1023px) {
        height: 14%;
    }

    @media (max-width: 740px) {
        height: 12.5%;
    }
`;

const LivemapIframe = styled.div`
    display: flex;
    width: 100%;
    height: 560px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    margin-bottom : 10%;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.2);
`;

const IndexPage = ({ data }) => {

    const { t } = useTranslation('indoor');

    const experience = [
        {
            icon: 'icon-map2',
            text: t('DESCRIPTION_INDOOR')
        },
        {
            icon: 'icon-location-current',
            text: t('DESCRIPTION_ACCURATE_LOCALIZATION')
        },
        {
            icon: 'icon-user-plus',
            text: t('DESCRIPTION_STEP_BY_STEP')
        },
        {
            icon: 'icon-mobile1',
            text: t('DESCRIPTION_ADVANCE_SERVICES')
        }];

    const icons = [
        {
            icon: 'icon-address',
            text: t('PROPRIETARY_FUSION')
        },
        {
            icon: 'icon-phonelink_setup',
            text: t('SEAMLESS_NAV')
        },
        {
            icon: 'icon-map3',
            text: t('COMPATIBLE_IPS_PROVIDER')
        }];

    const search = [

        {
            icon: 'icon-center_focus_strong',
            text: t('DESCRIPTION_SEARCH_COMBINE')
        },
        {
            icon: 'icon-search2',
            text: t('DESCRIPTION_IMMERSIVE')
        },
        {
            icon: 'icon-list3',
            text: t('DESCRIPTION_GUIDAGE')
        },
        {
            icon: 'icon-location-current',
            text: t('DESCRIPTION_ENVIRONMENT')
        },
        {
            icon: 'icon-map2',
            text: t('DESCRIPTION_PROMOTE')
        }];

    const enrich = [
        {
            icon: 'icon-search2',
            text: t('DESCRIPTION_BUILD_BRAND')
        },
        {
            icon: 'icon-tablet_mac',
            text: t('DESCRIPTION_EASILY_MANAGE')
        },
        {
            icon: 'icon-map2',
            text: t('DESCRIPTION_HIGH_AVAILABILITY')
        },
        {
            icon: 'icon-time',
            text: t('DESCRIPTION_DEPLOY_MAPS')
        },
        {
            icon: 'icon-location-current',
            text: t('DESCRIPTION_REAL_TIME')
        }];

    const technology = [
        {
            icon: 'icon-search2',
            text: t('DESCRIPTION_PROPRIETARY_VPS')
        },
        {
            icon: 'icon-camera1',
            text: t('DESCRIPTION_FAST_POSITIONING')
        },
        {
            icon: 'icon-explore1',
            text: t('DESCRIPTION_SIMPLE_CAPTURE')
        },
        {
            icon: 'icon-tablet_mac',
            text: t('DESCRIPTION_AVAILABLE')
        }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_INDOOR')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_INDOOR')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_USED_INDOOR')}
                        size='large'
                        customersLogos={[
                            {image: '/images/industries/sncf-logo.png'},
                            {image: '/images/industries/events/vivatech.png'},
                            {image: '/images/industries/sport/roland-garros-logo.png'},
                            {image: '/images/industries/events/accor-arena-logo.png'},
                            {image: '/images/products/indoor/logo_ses.svg'},
                            {image: '/images/products/indoor/logo_ratp.svg'},
                            {image: '/images/industries/versailles-logo.png'},
                            {image: '/images/products/indoor/bahn-logo.svg'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_GIVE_AUDIENCE')}
                        videoUrl={t('VIDEO')}
                        altDescription='video preview of our functionalities'
                        titleButton={t('TITLE_DEMARRER')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_CREATON_PUBLISH')
                            },
                            {
                                icon: 'icon-layers1',
                                text: t('DESCRIPTION_EASIER_MOBILITY')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_IMPROVED_SHOPPING')
                            },
                            {
                                icon: 'icon-search2',
                                text: t('DESCRIPTION_DAY_TO_DAY')
                            },
                            {
                                icon: 'icon-event_note',
                                text: t('DESCRIPTION_VISITOR_OPERATOR')
                            }
                        ]}
                    />

                    <ContentReverse
                        title={t('TITLE_PROVIDE_EXPERIENCE')}
                        computerLeft={data.navStep.childImageSharp}
                        altDescription='mobile with indoor step by step navigation'
                        titleButton={t('TITLE_LEARN_MORE')}
                        rightIcons={experience}
                        urlFooter='/contact/'
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_BACKGROUND_MAPS')}
                        pictureRight={data.rolandGarros.childImageSharp}
                        altDescription='mobile with indoor step by step navigation'
                        titleButton={t('TITLE_LEARN_MORE')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_2D_3D_MAP')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_MULTI_LEVEL')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_BRANDED_MAPS')
                            },
                            {
                                icon: 'icon-mobile1',
                                text: t('DESCRIPTION_MULTIPLE_VERSION')
                            }
                        ]}
                    />

                    <BgDarkBlue></BgDarkBlue>

                    <HeaderMap customerCicleImageUrl='/images/industries/sport/roland-garros-logo.png'
                        by={t('TEXT_BY')}
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_ROLAND')}
                        text={t('TITLE_DESCRIPTION_ROLAND')}
                        textPointInfos={t('TITLE_POINTS_ROLAND')}
                        textViewsInfos={t('TITLE_VIEWS_ROLAND')}
                        descriptionCustomer={t('TITLE_ROLAND_DESCRIPTION_MAP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_RG_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_RG_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_RG_2')}
                    />

                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/dom.html?emmid=21451&token=7ETI43N4ZZGARWPHJ57WQAARW"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </LivemapIframe>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_AR_TECHNO')}
                            text={t('DESCRIPTION_PUBLIC_ENVIRONNEMENT')}
                            titleButton={t('TITLE_SEE_PARTNERS')}
                            icons={icons}
                            urlFooter='/partners/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_INDOOR_POSITION')}
                        pictureRight={data.heathrow.childImageSharp}
                        altDescription='mobile with indoor step by step navigation'
                        titleButton={t('TITLE_GET_DEMO')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_LOCATION_PRECISE')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_GEOLOCATION')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_COMBINING_WEMAP')
                            },
                            {
                                icon: 'icon-mobile1',
                                text: t('DESCRIPTION_LOCATION_STATIC')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_LOCATION_ADVANCED')
                            }
                        ]}
                    />

                    <ContentReverse
                        title={t('TITLE_VISUAL_SEARCH')}
                        videoUrl='/images/products/wemap-navigation/ar-outdoor-wemap.mp4'
                        pictureLeftIphoneX={data.whiteMobile.childImageSharp}
                        altDescription='mobile with augmented reality and navigation for events'
                        titleButton={t('TITLE_BUTTON_EXPLORE')}
                        rightIcons={search}
                        urlFooter='https://blog.getwemap.com/nouvelle-version-du-moteur-de-recherche-wemap-3deb9bd86ef1'
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_EFFORTLESS_NAV')}
                        videoUrl='/images/products/indoor/sncf-navigation.mp4'
                        pictureRight={data.whiteMobile.childImageSharp}
                        altDescription='mobile with indoor augmented reality navigation in store'
                        titleButton={t('TITLE_BUTTON_LEARN_AR')}
                        urlFooter='/augmented-reality/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_INSTANT_ORIENTATION')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_EASY_FOLLOW')
                            },
                            {
                                icon: 'icon-center_focus_strong',
                                text: t('DESCRIPTION_AR_MAP_COMBINED')
                            },
                            {
                                icon: 'icon-my_location',
                                text: t('DESCRIPTION_SMART_ROUTING')
                            }
                        ]}
                    />

                    <ContentReverse
                        title={t('TITLE_PROFESSIONAL_TOOL')}
                        computerLeft={data.feno.childImageSharp}
                        altDescription='computer with our professionnal tool for map making'
                        titleButton={t('TITLE_LEARN_MORE')}
                        rightIcons={enrich}
                        urlFooter='/products/'
                    />

                    <BgDarkBlue></BgDarkBlue>

                    <HeaderMap customerCicleImageUrl='/images/industries/events/vivatech.png'
                        by={t('TEXT_BY')}
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_VIVATECH')}
                        text={t('TITLE_DESCRIPTION_VIVATECH')}
                        textPointInfos={t('TITLE_POINTS_VIVATECH')}
                        textViewsInfos={t('TITLE_VIEWS_VIVATECH')}
                        descriptionCustomer={t('TITLE_VIVATECH_DESCRIPTION_MAP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_VIVATECH_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_VIVATECH_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_VIVATECH_2')}
                    />

                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/dom.html?emmid=19802&token=7GGFU6PSBDO13Y1PQZD8SFNQK"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </LivemapIframe>

                    <ContentReverse
                        title={t('TITLE_POSITIONNING')}
                        videoUrl='/images/products/indoor/vps.mp4'
                        pictureLeftPixel={data.googlePixel.childImageSharp}
                        altDescription='mobile with augmented reality and visual positionning system inside railway station'
                        titleButton={t('TITLE_LEARN_MORE_TECH')}
                        rightIcons={technology}
                        urlFooter='https://blog.getwemap.com/relocalisation-par-vision-computer-vision-134612359fba'
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_LEVERAGE_POWER')}
                        pictureRight={data.indoorNav.childImageSharp}
                        altDescription='mobile with indoor mall augmented reality navigation'
                        titleButton={t('TITLE_BUTTON_EXPLORE')}
                        urlFooter='/wemap-pro/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_CONNECT_CONTENT')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_CREATE_DEPLOY')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_ENRICH_MAPS')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_MONETIZE_MAP')
                            }
                        ]}
                    />

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with personne navigating on phone'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_SEE_RETAIL_PAGE')}
                        buttonUrl='/shop/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
    whiteMobile: file(
        relativePath: {eq: "products/wemap-ar/white_mobile_augmented-reality.png"}
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, width: 320, layout: FIXED)
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  googlePixel: file(relativePath: {eq: "products/indoor/google-pixel-3-v2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  mobileScreen: file(relativePath: {eq: "products/indoor/mobile_screen.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 294, layout: FIXED)
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  mockup: file(relativePath: {eq: "products/indoor/cfe-tool.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FIXED)
    }
  }
  navStep: file(relativePath: {eq: "products/indoor/nav_step_1.png"}) {
    childImageSharp {
        gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  rolandGarros: file(relativePath: {eq: "products/indoor/roland-garros.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 520, layout: FIXED)
    }
  }
  heathrow: file(relativePath: {eq: "products/indoor/heathrow-vps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 520, layout: FIXED)
    }
  }
  feno: file(relativePath: {eq: "products/indoor/feno.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 390, layout: FIXED)
    }
  }
  indoorNav: file(relativePath: {eq: "products/indoor/indoor-nav-v2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  banner: file(relativePath: {eq: "products/indoor/banner-wemap-indoor.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
